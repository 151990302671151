import { useRecoilState } from 'recoil';
import ModalMessage from './ModalMessage';

import { modalState } from '../../../recoil/modal';

export const MODAL_TYPES = {
    ConfirmModal: 'ConfirmModal',
    AlertModal: 'AlertModal',
} as const;

const GlobalModal = () => {
    const modalProps = useRecoilState(modalState)[0];

    if (!modalProps) {
        return null;
    }

    const renderComponent = () => {
        return <ModalMessage {...modalProps} />;
    };

    return <>{renderComponent()}</>;
};

export default GlobalModal;
