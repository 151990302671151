import React, {
    ForwardedRef,
    forwardRef,
    FunctionComponent,
    LegacyRef,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTabs } from './context';

interface OwnProps {
  value?: string;
  className?: string;
  children?: React.ReactNode;
  ref?: ForwardedRef<unknown>;
}
const TabContent = React.forwardRef((props: OwnProps, refTabContent) => {
    const {
        value, children, className, ref = refTabContent, ...rest
    } = props;

    const context = useTabs();
    const isSelected = value === context.value;

    const tabContentClass = classNames(
        'tab-content',
        isSelected && 'tab-content-active',
        className,
    );

    return (
        <div
            role="tabpanel"
            tabIndex={0}
            className={tabContentClass}
            ref={ref as LegacyRef<HTMLDivElement> | undefined}
            {...rest}
        >
            {isSelected && children}
        </div>
    );
});

TabContent.propTypes = {
    value: PropTypes.string.isRequired,
};

export default TabContent;
