import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    cliente: {},
    eventos: {},
    eventosHistorico: {},
    dirty: false,
};

export const clienteSlice = createSlice({
    name: 'base/cliente',
    initialState,
    reducers: {
        setCliente: (state, action) => {
            state.cliente = action.payload;
        },
        setEventos: (state, action) => {
            state.eventos = action.payload;
        },
        setEventosHistorico: (state, action) => {
            state.eventosHistorico = action.payload;
        },
        setDirty: (state, action) => {
            state.dirty = action.payload;
        },
    },
});

export const {
    setCliente, setEventos, setEventosHistorico, setDirty,
} = clienteSlice.actions;

export default clienteSlice.reducer;
