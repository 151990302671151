import useModal from 'utils/hooks/useModal';
import { Button, Dialog } from 'components/ui';
import React from 'react';

export interface ModalProps {
  title?: string;
  message: string | React.ReactNode;
  cancelAction?: () => void;
  buttons: {
    label: string;
    variant: string;
    color?: string;
    onClickHandler: () => void;
  }[];
}

export default function ModalMessage({
    title,
    message,
    cancelAction,
    buttons,
}: ModalProps) {
    const { hideModal } = useModal();

    const handleCancel = () => {
        if (cancelAction) {
            cancelAction();
        }

        hideModal();
    };

    return (
        <>
            <Dialog
                overlayClassName="z-40"
                isOpen
                onClose={handleCancel}
                onRequestClose={handleCancel}
            >
                <h6 className="mb-4 font-medium">{title}</h6>

                {message}

                <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                    {buttons.map((button, index) => (
                        <Button
                            size="sm"
                            key={index}
                            variant={button.variant}
                            color={button.color}
                            onClick={() => {
                                button.onClickHandler();
                                hideModal();
                            }}
                        >
                            {button.label}
                        </Button>
                    ))}
                    <Button size="sm" onClick={handleCancel}>
                        Cancelar
                    </Button>
                </div>
            </Dialog>
        </>
    );
}
