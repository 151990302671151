import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import CloseButton from '../CloseButton';

interface OwnProps {
  width?: number | string;
  height?: number | string;
  closeTimeoutMS?: number;
  closable?: boolean;
  title?: string;
  footer?: string | React.ReactNode;
  className?: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  headerClass?: string;
  footerClass?: string;
  bodyClass?: string;
  showBackdrop?: boolean;
  lockScroll?: boolean | string;
  isOpen?: boolean;
  bodyOpenClassName?: string;
  portalClassName?: string;
  overlayClassName?: string;
  buttons?: React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
  onRequestClose?: () => void;
}

interface GetStyle {
  dimensionClass: string;
  contentStyle: { width: number | string } | { height: number | string };
  motionStyle: { [x: string]: string };
}

const Drawer: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        children,
        className,
        closable,
        width,
        height,
        isOpen,
        onClose,
        closeTimeoutMS,
        placement,
        bodyOpenClassName,
        portalClassName,
        overlayClassName,
        title,
        footer,
        headerClass,
        footerClass,
        bodyClass,
        showBackdrop,
        lockScroll,
        buttons,
        ...rest
    } = props;

    const onCloseClick = () => {
        if (onClose) onClose();
    };

    const renderCloseButton = <CloseButton onClick={onCloseClick} />;

    const getStyle = (): GetStyle => {
        const auxWidth = width || 400;
        const auxHeight = height || 400;

        if (placement === 'left' || placement === 'right') {
            return {
                dimensionClass: 'vertical',
                contentStyle: { width: auxWidth },
                motionStyle: {
                    [placement]: `-${auxWidth}${typeof auxWidth === 'number' && 'px'}`,
                },
            };
        }

        return {
            dimensionClass: 'horizontal',
            contentStyle: { height: auxHeight },
            motionStyle: {
                [placement || 'right']: `-${auxHeight}${
                    typeof auxHeight === 'number' && 'px'
                }`,
            },
        };
    };

    const { dimensionClass, contentStyle, motionStyle }: GetStyle = getStyle();

    return (
        <Modal
            className={{
                base: classNames('drawer', className),
                afterOpen: 'drawer-after-open',
                beforeClose: 'drawer-before-close',
            }}
            overlayClassName={{
                base: classNames(
                    'drawer-overlay',
                    overlayClassName,
                    !(showBackdrop || showBackdrop === undefined) && 'bg-transparent',
                ),
                afterOpen: 'drawer-overlay-after-open',
                beforeClose: 'drawer-overlay-before-close',
            }}
            portalClassName={classNames('drawer-portal', portalClassName)}
            bodyOpenClassName={classNames(
                'drawer-open',
                (lockScroll || lockScroll === undefined) && 'drawer-lock-scroll',
                bodyOpenClassName,
            )}
            ariaHideApp={false}
            isOpen={isOpen || false}
            closeTimeoutMS={closeTimeoutMS || 300}
            {...rest}
        >
            <motion.div
                className={classNames('drawer-content', dimensionClass)}
                style={{ ...contentStyle, minHeight: '60%' }}
                initial={motionStyle}
                animate={{
                    [placement || 'right']: isOpen
                        ? 0
                        : motionStyle[placement || 'right'],
                }}
            >
                {title || closable || closable === undefined ? (
                    <div
                        className={`${classNames(
                            'drawer-header',
                            headerClass,
                        )} border-none pb-0`}
                    >
                        {typeof title === 'string' ? (
                            <h5 className="font-bold">{title}</h5>
                        ) : (
                            <span>{title}</span>
                        )}
                        {(closable || closable === undefined) && renderCloseButton}
                    </div>
                ) : null}
                <div
                    className={`${classNames(
                        'drawer-body',
                        bodyClass,
                    )} flex flex-col justify-between`}
                >
                    <div className="h-full">{children}</div>
                    {buttons && <div style={{ paddingTop: '35px' }}>{buttons}</div>}
                </div>
                {footer && (
                    <div className={classNames('drawer-footer', footerClass)}>
                        {footer}
                    </div>
                )}
            </motion.div>
        </Modal>
    );
};

export default Drawer;
