import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    notificacaoState: 'LIDAS',
};

export const corporaConfigSlice = createSlice({
    name: 'base/corporaConfig',
    initialState,
    reducers: {
        setNotificacaoDisponivel: (state, action) => {
            state.notificacaoState = 'DISPONIVEL';
        },
        setNotificacoesLidas: (state, action) => {
            state.notificacaoState = 'LIDAS';
        },
        reset: () => initialState,
    },
});

export const { setNotificacaoDisponivel, setNotificacoesLidas, reset } = corporaConfigSlice.actions;

export default corporaConfigSlice.reducer;
