import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import tw, { theme } from 'twin.macro';
import get from 'lodash/get';
import { HiCheck, HiChevronDown, HiX } from 'react-icons/hi';
import { useConfig } from '../ConfigProvider';
import { useForm } from '../Form/context';
import { useInputGroup } from '../InputGroup/context';
import Spinner from '../Spinner';
import { CONTROL_SIZES } from '../utils/constant';
import { themeConfig } from '../../../configs/theme.config';
import { TwColor, TwColorLevel } from '../../../types/config/theme';
import { Index, Form } from '../../../types/ui';

interface OwnProps {
  size?: string | number;
  style?: StyleSheet;
  className?: string;
  form?: Form;
  field?: Index;
  placeholder?: string;
  value?: Option | null;
  onChange?: (e: any) => void;
  options?: Option[];
  components?: any;
}

interface OwnPropsDefaultOption {
  innerProps: React.HTMLProps<HTMLDivElement>;
  label?: string;
  selectProps: typeof themeConfig;
  isSelected?: boolean;
  isDisabled?: boolean;
  isFocused?: boolean;
}

export interface Option {
  label: string;
  value: string;
}

const DefaultOption: FunctionComponent<OwnPropsDefaultOption> = ({
    innerProps,
    label,
    selectProps,
    isSelected,
    isDisabled,
}: OwnPropsDefaultOption) => {
    const { themeColor } = selectProps || themeConfig;
    return (
        <div
            className={`select-option whitespace-nowrap ${isSelected && 'selected'} ${
                isDisabled && 'disabled'
            }`}
            {...innerProps}
        >
            <span className="ml-2">{label}</span>
            {isSelected && (
                <HiCheck className={`text-${themeColor} dark:text-white text-xl`} />
            )}
        </div>
    );
};

const DefaultDropdownIndicator = () => {
    return (
        <div className="select-dropdown-indicator">
            <HiChevronDown />
        </div>
    );
};

const DefaultClearIndicator = (props: any) => {
    const {
        innerProps: { ref, ...restInnerProps },
    } = props;
    return (
        <div {...restInnerProps} ref={ref}>
            <div className="select-clear-indicator">
                <HiX />
            </div>
        </div>
    );
};

const DefaultLoadingIndicator = ({
    selectProps,
}: {
  selectProps: typeof themeConfig;
}) => {
    const { themeColor } = selectProps;
    return <Spinner className={`select-loading-indicatior text-${themeColor}`} />;
};

const Select: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        size, style, className, form, field, components, ...rest
    } = props;

    const {
        themeColor, controlSize, primaryColorLevel, mode,
    } = useConfig();
    const formControlSize = useForm()?.size;
    const inputGroupSize = useInputGroup()?.size;

    const selectSize = size || inputGroupSize || formControlSize || controlSize;

    const twColor: TwColor = theme`colors`;
    const twHeight = theme`height`;

    let isInvalid = false;

    if (form && field) {
        const { touched, errors } = form;

        const touchedField = get(touched, field.name);
        const errorField = get(errors, field.name);

        isInvalid = touchedField && errorField;
    }

    const getBoxShadow = (state: OwnPropsDefaultOption) => {
        const shadaowBase = '0 0 0 1px ';

        if (isInvalid) {
            return shadaowBase + twColor.red['500'];
        }

        if (state.isFocused) {
            return (
                shadaowBase
        + twColor[themeColor as keyof TwColor][
          primaryColorLevel as keyof TwColorLevel
        ]
            );
        }

        return 'none';
    };

    const styles = {
        control: (provided: StyleSheet, state: OwnPropsDefaultOption) => {
            return {
                ...provided,
                height: twHeight[CONTROL_SIZES[selectSize]],
                minHeight: twHeight[CONTROL_SIZES[selectSize]],
                '&:hover': {
                    boxShadow: getBoxShadow(state),
                    cursor: 'pointer',
                },
                boxShadow: getBoxShadow(state),
                borderRadius: tw`rounded-md`.borderRadius,
                ...(isInvalid ? { borderColor: twColor.red['500'] } : {}),
            };
        },
        input: (css: StyleSheet) => {
            return {
                ...css,
                input: {
                    outline: 'none',
                    outlineOffset: 0,
                    boxShadow: 'none !important',
                },
            };
        },
        menu: (provided: StyleSheet) => ({ ...provided, zIndex: 50 }),
        ...style,
    };

    const selectClass = classNames('select', `select-${selectSize}`, className);

    return (
        <ReactSelect
            className={selectClass}
            classNamePrefix="select"
            styles={styles as any}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    neutral20:
            mode === 'dark' ? twColor.gray['600'] : twColor.gray['300'],
                    neutral30:
            mode === 'dark' ? twColor.gray['600'] : twColor.gray['300'],
                    neutral80: twColor.gray['700'],
                    primary25: twColor[themeColor as keyof TwColor]['50'],
                    primary50: twColor[themeColor as keyof TwColor]['100'],
                    primary:
            twColor[themeColor as keyof TwColor][
              primaryColorLevel as keyof TwColorLevel
            ],
                },
            })}
            components={{
                IndicatorSeparator: () => null,
                Option: DefaultOption,
                LoadingIndicator: DefaultLoadingIndicator,
                DropdownIndicator: DefaultDropdownIndicator,
                ClearIndicator: DefaultClearIndicator,
                ...components,
            }}
            {...field}
            {...rest}
        />
    );
};

export default Select;
