import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { theme } from 'twin.macro';
import CloseButton from '../CloseButton';
import useWindowSize from '../hooks/useWindowSize';

interface OwnProps {
  children?: React.ReactNode;
  className?: string;
  closable?: boolean;
  width?: string | number;
  height?: string | number;
  style?: StyleSheet;
  isOpen?: boolean;
  onClose?: () => void;
  onRequestClose?: () => void;
  bodyOpenClassName?: string;
  portalClassName?: string;
  overlayClassName?: string;
  contentClassName?: string;
  closeTimeoutMS?: number;
}

const Dialog: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const currentSize = useWindowSize();

    const {
        children,
        className,
        closable,
        width,
        height,
        style,
        isOpen,
        onClose,
        bodyOpenClassName,
        portalClassName,
        overlayClassName,
        contentClassName,
        closeTimeoutMS,
    } = props;

    const onCloseClick = () => {
        if (onClose) onClose();
    };

    const renderCloseButton = (
        <CloseButton
            onClick={onCloseClick}
            className="ltr:right-6 rtl:left-6"
            absolute
        />
    );

    const contentStyle = {
        content: {
            inset: 'unset',
            width: width || 520,
            height,
        },
        ...style,
    };

    contentStyle.content.width = window.innerWidth < 401 ? 'auto' : '450';

    if (height !== undefined) {
        contentStyle.content.height = height;
    }

    const defaultDialogContentClass = 'dialog-content';

    const dialogClass = classNames(defaultDialogContentClass, contentClassName);

    return (
        <Modal
            className={{
                base: classNames('dialog', className),
                afterOpen: 'dialog-after-open',
                beforeClose: 'dialog-before-close',
            }}
            overlayClassName={{
                base: classNames('dialog-overlay', overlayClassName),
                afterOpen: 'dialog-overlay-after-open',
                beforeClose: 'dialog-overlay-before-close',
            }}
            portalClassName={classNames('dialog-portal', portalClassName)}
            bodyOpenClassName={classNames('dialog-open', bodyOpenClassName)}
            ariaHideApp={false}
            isOpen={isOpen === undefined ? false : isOpen}
            style={{ ...contentStyle }}
            closeTimeoutMS={closeTimeoutMS || 150}
        >
            <motion.div
                className={dialogClass}
                initial={{ transform: 'scale(0.9)' }}
                animate={{
                    transform: isOpen ? 'scale(1)' : 'scale(0.9)',
                }}
            >
                {(closable === undefined || closable) && renderCloseButton}
                {children}
            </motion.div>
        </Modal>
    );
};

export default Dialog;
