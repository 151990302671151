import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState = {
    infoMeioPagamento: {},
    lancamentos: [],
    scope: 'geral', //informa o escopo do financeiro
    dirty: true //informa se os lançamentos foram alterados e precisam ser atualizados

};

// Informações para ajudar o financeiro a funcionar sem ter que acionar sempre
// o backend
export const financeiroSlice = createSlice({
    name: 'base/financeiro',
    initialState,
    reducers: {
        setInfoMeioPagamento: (state, action) => {
            state.infoMeioPagamento = action.payload;
        },
        setLancamentos: (state, action) => {
            state.lancamentos = action.payload;
        },
        addLancamento: (state, action) => {
            state.lancamentos.push(action.payload);
        },
        updateLancamento(state, action) {
            const id = action.payload.id;
            const index = state.lancamentos.findIndex((lancamento) => lancamento.id === id);

            if (index !== -1) {
                state.lancamentos = [
                    ...state.lancamentos.slice(0, index),
                    action.payload,
                    ...state.lancamentos.slice(index + 1)
                ];
            }
        },
        removeLancamento(state, action) {
            const id = action.payload;
            const index = state.lancamentos.findIndex((lancamento) => lancamento.id === id);

            if (index !== -1) {
                state.lancamentos = [
                    ...state.lancamentos.slice(0, index),
                    ...state.lancamentos.slice(index + 1)
                ];
            }
        },

        setDirty: (state, action) => {
            state.dirty = action.payload;
        },
        setScopeGeral: (state, action) => {
            state.scope = "geral";
        },
        setScopeIndividual: (state, action) => {
            state.scope = "local";
        },
    },
});

export const {
    setInfoMeioPagamento, setLancamentos, setDirty, setScopeGeral, setScopeIndividual, addLancamento, updateLancamento,
    removeLancamento
} = financeiroSlice.actions;

export default financeiroSlice.reducer;
