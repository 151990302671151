import React, {
    FormEvent,
    ForwardedRef,
    FunctionComponent,
    ReactNode,
    useCallback,
    useContext,
} from 'react';
import classNames from 'classnames';
import Menu from './Menu';
import MenuContext from './context/menuContext';
import DropdownItem from './DropdownItem';
import { Placement } from '../../../types/ui';

interface OwnProps {
  placement: Placement;
  title?: string | ReactNode;
  className?: string;
  eventKey?: string;
  onToggle?: (eventKey: string, e: FormEvent) => void;
  onSelect?: (eventKey: string, e: FormEvent) => void;
  trigger?: string;
  children?: React.ReactNode;
  style?: StyleSheet;
  activeKey?: string;
  hidden?: boolean;
  id?: string;
  ref?: ForwardedRef<unknown>;
}

const DropdownMenu = React.forwardRef((props: OwnProps, refDropdownMenu) => {
    const {
        onToggle,
        eventKey,
        title,
        className,
        placement,
        ref = refDropdownMenu,
        ...rest
    } = props;

    const parentMenu = useContext(MenuContext);

    const handleToggleSubmenu = useCallback(
        (_: any, e: FormEvent) => {
            if (eventKey) onToggle?.(eventKey, e);
        },
        [eventKey, onToggle],
    );

    const dropdownMenuDefaultClass = 'dropdown-menu';
    const dropdownMenuPositionClass = placement;

    const dropdownMenuClass = classNames(
        dropdownMenuDefaultClass,
        dropdownMenuPositionClass,
        className,
    );

    const dropdownSubmenuClass = classNames(
        dropdownMenuDefaultClass,
        'dropdown-submenu',
    );

    const dropdownSubmenu = (
        <Menu
            className={dropdownSubmenuClass}
            ref={ref}
            onToggle={handleToggleSubmenu}
            placement={placement}
            {...rest}
        />
    );

    if (parentMenu) {
        const { trigger } = props;
        const itemClassName = classNames(className);

        return (
            <DropdownItem
                trigger={trigger}
                className={itemClassName}
                submenu={dropdownSubmenu}
                eventKey={eventKey}
            >
                {title}
            </DropdownItem>
        );
    }

    return (
        <Menu
            className={dropdownMenuClass}
            placement={placement}
            ref={ref}
            {...rest}
        />
    );
});

export default DropdownMenu;
