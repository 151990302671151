import { createSlice } from '@reduxjs/toolkit';
import { SlicerName } from '../types';

export const initialState = {
    appState: '',
    lid: '', // lancamento Id
    dadosPesquisa: [],
    currentRouteKey: '',
    nomeCliente: '',
    cid: '', // cliente id
    dataHora: '',
    game: [
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
    ],
    observacao: ['', '', '', '', '', ''],
    lateralidade: {},
    diagnostico: '',
};

export const commonSlice = createSlice({
    name: SlicerName.Common,
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload;
        },
        setGame: (state, action) => {
            state.game = action.payload;
        },
        setNomeCliente: (state, action) => {
            state.nomeCliente = action.payload;
        },
        setCodigoCliente: (state, action) => {
            state.cid = action.payload;
        },
        setDataHora: (state, action) => {
            state.dataHora = action.payload;
        },
        setLancamentoId: (state, action) => {
            state.lid = action.payload;
        },
        stIniciarAgendamento: (state, action) => {
            state.lid = action.payload;
            state.appState = 'INICIAR_AGENDA';
        },
        stEncerrarAgendamento: (state) => {
            state.lid = '';
            state.appState = '';
        },
        setAppState: (state, action) => {
            state.appState = action.payload;
        },
        setDadosPesquisa: (state, action) => {
            state.dadosPesquisa = action.payload;
        },
        setObservacao: (state, action) => {
            state.observacao = action.payload;
        },
        setLateralidade: (state, action) => {
            state.lateralidade = action.payload;
        },
        setDiagnostico: (state, action) => {
            state.diagnostico = action.payload;
        },

        reset: () => initialState,
    },
});

export const {
    setDadosPesquisa,
    stEncerrarAgendamento,
    stIniciarAgendamento,
    setCurrentRouteKey,
    setGame,
    setDataHora,
    setObservacao,
    setLateralidade,
    setDiagnostico,
    reset,
} = commonSlice.actions;

export default commonSlice.reducer;
