import { useSelector, useDispatch } from 'react-redux';
import { setUser, initialState } from 'store/auth/userSlice';
import { _apiSignIn, _apiSignOut, _apiSignUp } from 'services/AuthService';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { PERSIST_STORE_NAME } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';

function useAuth() {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const query = useQuery();

    const { token, signedIn } = useSelector((state) => state.auth.session);

    const signIn = async (values) => {
        try {
            const resp = await _apiSignIn(values);

            if (resp) {
                const token = resp.data.jwt;
                dispatch(onSignInSuccess(token));
                if (resp.data) {
                    dispatch(
                        setUser({
                            avatar: '',
                            userName: resp.data.usuario.username,
                            authority: [],
                            roles: resp.data.usuario.roles,
                            email: resp.data.usuario.email,
                            uid: resp.data.usuario.id,
                            status: resp.data.usuario.status,
                            idRef: resp.data.usuario.idRef,
                            token,
                            profissional: resp.data.profissional,
                        }),
                    );
                }

                if (resp.data.usuario.status === 'ONBOARDING_PENDENTE') navigate('/welcome');
                else navigate(appConfig.authenticatedEntryPath);
                return {
                    status: 'success',
                    message: '',
                };
            }

            return {
                status: 'failed',
                message: 'Login falhou - OE1010',
            };
        } catch (errors) {
            if (errors.response === undefined) {
                console.log(errors);
                return {
                    status: 'failed',
                    message: `Não foi possível conectar com servidor - por favor contate o suporte ${errors.toString()}`,
                };
            }
            if (errors.response.status === 406) {
                return {
                    status: 'failed',
                    message: 'Conta bloqueada - por favor contate o suporte',
                };
            }
            return {
                status: 'failed',
                message: 'Usuário/Senha inválidos. Tente novamente.',
            };
        }
    };

    const signUp = async (values) => {
        try {
            const resp = await _apiSignUp(values);
            // navigate("/sign-in");
            return {
                status: 'success',
                message: '',
            };
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            };
        }
    };

    const handleSignOut = () => {
        dispatch(onSignOutSuccess());
        dispatch(setUser(initialState));
        window.location.replace(appConfig.unAuthenticatedEntryPath);
    };

    const signOut = async () => {
        handleSignOut();
    };

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    };
}

export default useAuth;
