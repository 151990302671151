import React, {
    ForwardedRef,
    forwardRef,
    FunctionComponent,
    LegacyRef,
} from 'react';
import classNames from 'classnames';
import { TabsContextProvider } from './context';
import useControllableState from '../hooks/useControllableState';

interface OwnProps {
  value?: string;
  onChange?: () => void;
  defaultValue?: string;
  variant?: 'underline' | 'pill';
  className?: string;
  children?: React.ReactNode;
  ref?: ForwardedRef<unknown>;
}

const Tabs = React.forwardRef((props: OwnProps, refTabs) => {
    const {
        value: valueProp,
        onChange,
        defaultValue,
        variant,
        className,
        ref = refTabs,
        ...rest
    } = props;

    const [value, setValue] = useControllableState({
        prop: valueProp,
        onChange,
        defaultProp: defaultValue,
    });

    const tabsClass = classNames('tabs', className);

    return (
        <TabsContextProvider
            value={{
                value,
                onValueChange: setValue,
                variant: variant || 'underline',
            }}
        >
            <div
                className={tabsClass}
                {...rest}
                ref={ref as LegacyRef<HTMLDivElement> | undefined}
            />
        </TabsContextProvider>
    );
});

export default Tabs;
