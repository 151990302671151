import { createSlice } from '@reduxjs/toolkit';
import { SlicerName } from '../types';

export const initialState = {
    dados: {
        dom: [],
        seg: [],
        ter: [],
        qua: [],
        qui: [],
        sex: [],
        sab: [],
    },
    servicos: [],
    localidades: [],
};

export const configAgendaSlice = createSlice({
    name: SlicerName.ConfigAgenda,
    initialState,
    reducers: {
        setDados: (state, action) => {
            state.dados = action.payload;
        },
        addConfig: (state, action) => {
            state.dados[action.payload.dia].push(action.payload.valor);
        },
        replicateConfig: (state, action) => {
            if (!state.dados[action.payload.copiedDay]?.length) return;
            if (!state.dados[action.payload.copiedDay]?.length) return;

            action.payload.selectedDays.forEach((item) => {
                state.dados[item] = state.dados[action.payload.copiedDay];
            });
        },
        updateHorarioConfig: (state, action) => {
            if (action.payload.periodo === '0') {
                state.dados[action.payload.dia][action.payload.index].inicio = action.payload.valor;
            } else {
                state.dados[action.payload.dia][action.payload.index].fim = action.payload.valor;
            }
        },
        updateLocalConfig: (state, action) => {
            state.dados[action.payload.dia][action.payload.index].localidade = action.payload.valor;
        },
        updateServicosConfig: (state, action) => {
            state.dados[action.payload.dia][action.payload.index].servicos = action.payload.valor;
        },
        limparServicos: (state, action) => {
            state.dados[action.payload.dia][action.payload.index].servicos = [];
        },
        updateModalidadeConfig: (state, action) => {
            state.dados[action.payload.dia][action.payload.index].modalidade = action.payload.valor;
        },
        limparDia: (state, action) => {
            state.dados[action.payload.dia][action.payload.index].status = 'EXCLUIDO';
        },
        setServicos: (state, action) => {
            state.servicos = action.payload;
        },
        toggleServico: (state, action) => {
            const idx = state.servicos.findIndex(
                (servico) => servico.id === action.payload.id,
            );
            state.servicos[idx].status = state.servicos[idx].status === 'ATIVO' ? 'INATIVO' : 'ATIVO';
        },
        toggleLocalidade: (state, action) => {
            const idx = state.localidades.findIndex(
                (it) => it.id === action.payload.id,
            );
            state.localidades[idx].status = state.localidades[idx].status === 'ATIVO' ? 'INATIVO' : 'ATIVO';
        },
        setLocalidades: (state, action) => {
            state.localidades = action.payload;
        },
    },
});

export const {
    updateModalidadeConfig,
    toggleServico,
    toggleLocalidade,
    setServicos,
    setLocalidades,
    setDados,
    updateHorarioConfig,
    updateLocalConfig,
    updateServicosConfig,
    addConfig,
    limparDia,
    limparServicos,
    replicateConfig,
} = configAgendaSlice.actions;

export default configAgendaSlice.reducer;
