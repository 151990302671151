import React, {
    createContext, useCallback, useContext, useState,
} from 'react';
import { DateTime } from 'luxon';

const CalendarContext = createContext();

export const useCalendarContext = () => useContext(CalendarContext);

export const CalendarProvider = ({ children }) => {
    const [dates, setDates] = useState([]);
    const [lastDate, setLastDate] = useState(null);
    const [firstDate, setFirstDate] = useState(null);
    const [events, setEvents] = useState({});
    const [showMonthlyView, setShowMonthlyView] = useState(false);

    const generateDatesRange = useCallback((startDate, days) => {
        const dates = [];
        for (let i = 0; i < days; i++) {
            dates.push(startDate.plus({ days: i }).toFormat('yyyyMMdd'));
        }
        return dates;
    }, []);

    const clearDates = () => {
        setFirstDate(null);
        setLastDate(null);
    };

    const resetDates = () => {
        const today = DateTime.now();
        const inicioMesAnterior = today.minus({ months: 1 }).startOf('month');
        const fimMesSeguinte = today.plus({ months: 1 }).endOf('month');

        setFirstDate(inicioMesAnterior);
        setLastDate(fimMesSeguinte);
    };

    return (
        <CalendarContext.Provider
            value={{
                dates,
                setDates,
                lastDate,
                setLastDate,
                firstDate,
                setFirstDate,
                events,
                setEvents,
                generateDatesRange,
                clearDates,
                showMonthlyView,
                setShowMonthlyView,
                resetDates,
            }}
        >
            {children}
        </CalendarContext.Provider>
    );
};
