import { initializeApp } from 'firebase/app';
import {
    getStorage,
    ref,
    uploadBytes,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from 'firebase/storage';
import { firebaseConfig } from 'constants/firebase.constant';
import {
    _atualizarUrlFotoPerfilTerapeuta,
    _atualizarUrlLogo,
} from './UsuarioService';

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export const _fbUploadFile = (file) => {
    const mountainsRef = ref(storage, 'mountains.jpg');
    uploadBytes(mountainsRef, file).then((snapshot) => {
        console.log(snapshot);
    });
};

export const _fbUploadFile2 = async (
    file,
    filename,
    afterUpload,
    skipAtualizacaoFotoPerfil = false,
    onError,
) => {
    const storageRef = ref(storage, filename);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
        'state_changed',
        (snapshot) => {},
        (error) => {
            // Handle unsuccessful uploads
            onError();
        },
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                if (skipAtualizacaoFotoPerfil) {
                    afterUpload({ urlFile: downloadURL });
                } else {
                    _atualizarUrlFotoPerfilTerapeuta({ urlFoto: downloadURL }).then(
                        (res) => {
                            afterUpload();
                        },
                    );
                }
            });
        },
    );
};

export const _uploadLogoClinica = async (file, filename, afterUpload) => {
    const storageRef = ref(storage, filename);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
        'state_changed',
        (snapshot) => {},
        (error) => {
            // Handle unsuccessful uploads
        },
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                _atualizarUrlLogo({ urlFoto: downloadURL }).then((res) => {
                    afterUpload();
                });
            });
        },
    );
};

export const _uploadFile = async (
    file,
    fileName,
    mimetype,
    callback,
) => {
    const fileRef = ref(storage, fileName);
    return await uploadBytes(fileRef, file, { contentType: mimetype }).then(
        (res) => {
            if (callback) {
                callback();
            }

            return res;
        },
    );
};

export const _fbGetFile = async (fileName) => {
    const storageRef = ref(storage, fileName);
    return await getDownloadURL(storageRef).then((downloadUrl) => downloadUrl);
};

export const _removeFile = async (fileName) => {
    const storageRef = ref(storage, fileName);
    return await deleteObject(storageRef);
};
