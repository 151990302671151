import React, { forwardRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomToolbar = () => {
    return (
        <div id="toolbar">
            <select
                className="ql-header"
                defaultValue=""
                onChange={(e) => e.persist()}
            >
                <option value="1">Título 1</option>
                <option value="2">Título 2</option>
                <option value="3">Título 3</option>
                <option value="4">Título 4</option>
                <option value="5">Título 5</option>
                <option value="6">Título 6</option>
                <option selected />
            </select>
            <button type="button" className="ql-bold" value="T">
                N
            </button>
            <button type="button" className="ql-italic">
                <i>I</i>
            </button>
            <button type="button" className="ql-underline">
                <u>U</u>
            </button>
            <button type="button" className="ql-link" />

            <span className="ql-formats">
                <button type="button" className="ql-list" value="ordered" />
                <button type="button" className="ql-list" value="bullet" />
            </span>
        </div>
    );
};

const modules = {
    toolbar: {
        container: '#toolbar',
    },
};

const RichTextEditor = forwardRef((props, ref) => {
    return (
        <div className="rich-text-editor w-full">
            {/* <CustomToolbar /> */}
            <ReactQuill
                ref={ref}
                {...props}
                className="text-base"
                modules={{
                    toolbar: [
                        [{ header: [1, 2, 3, false] }],
                        ['bold', 'italic', 'underline'],
                        [
                            { align: '' },
                            { align: 'center' },
                            { align: 'right' },
                            { align: 'justify' },
                        ],
                        [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' },
                        ],
                        ['link'],
                    ],
                }}
            />
        </div>
    );
});

RichTextEditor.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
];

export default RichTextEditor;
