import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { CgSpinner } from 'react-icons/cg';
import { useConfig } from '../ConfigProvider';

interface OwnProps {
  className?: string;
  color?: string;
  enableTheme?: boolean;
  isSpining?: boolean;
  size?: string;
  style?: StyleSheet;
}

const Spinner: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        className, color, enableTheme, isSpining, size, style, ...rest
    } = props;

    const { themeColor, primaryColorLevel } = useConfig();

    const spinnerColor = color || (enableTheme && `${themeColor}-${primaryColorLevel}`);

    const spinnerStyle = {
        height: size,
        width: size,
        ...style,
    };

    const spinnerClass = classNames(
        isSpining && 'animate-spin',
        spinnerColor && `text-${spinnerColor}`,
        className,
    );

    return <CgSpinner style={spinnerStyle} className={spinnerClass} {...rest} />;
};

export default Spinner;
