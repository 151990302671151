import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
    FunctionComponent,
    CSSProperties,
} from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { CurrencyInput } from 'react-currency-mask';
import { useConfig } from '../ConfigProvider';
import { useForm } from '../Form/context';
import { useInputGroup } from '../InputGroup/context';
import { CONTROL_SIZES, SIZES } from '../utils/constant';
import { Option } from '../Select/Select';
import { Index, Form } from '../../../types/ui';

interface OwnProps {
  size?: string | number;
  style?: StyleSheet;
  className?: string;
  type?: string;
  disabled?: boolean;
  invalid?: boolean;
  textArea?: boolean;
  currencyInput?: boolean;
  unstyle?: boolean;
  form?: Form;
  field?: Index;
  placeholder?: string;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (e: any) => void;
  options?: Option[];
  components?: any;
  onChangeValue?: any;
}

const Input: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        className,
        disabled,
        invalid,
        prefix,
        size,
        suffix,
        textArea,
        currencyInput,
        type,
        style,
        unstyle,
        field,
        form,
        onChangeValue,
        ...rest
    } = props;

    const [prefixGutter, setPrefixGutter] = useState(0);
    const [suffixGutter, setSuffixGutter] = useState(0);

    const {
        themeColor, controlSize, primaryColorLevel, direction,
    } = useConfig();
    const formControlSize = useForm()?.size;
    const inputGroupSize = useInputGroup()?.size;

    const inputSize = size || inputGroupSize || formControlSize || controlSize;

    const fixControlledValue = (val: string | number | undefined) => {
        if (typeof val === 'undefined' || val === null) {
            return '';
        }
        return val;
    };

    if ('value' in props) {
        rest.value = fixControlledValue(props.value);
        delete rest.defaultValue;
    }

    const isInvalid = useMemo(() => {
        let validate = false;
        if (!isEmpty(form)) {
            const { touched, errors } = form;

            if (field) {
                const touchedField = get(touched, field.name);
                const errorField = get(errors, field.name);
                validate = touchedField && errorField;
            }
        }
        if (typeof invalid === 'boolean') {
            validate = invalid;
        }
        return validate;
    }, [form, invalid, field]);

    const inputDefaultClass = 'input';
    const inputSizeClass = `input-${inputSize} h-${CONTROL_SIZES[inputSize]}`;
    const inputFocusClass = `focus:ring-${themeColor}-${primaryColorLevel} focus-within:ring-${themeColor}-${primaryColorLevel} focus-within:border-${themeColor}-${primaryColorLevel} focus:border-${themeColor}-${primaryColorLevel}`;
    const inputWrapperClass = `input-wrapper ${
        prefix || suffix ? className : ''
    }`;
    const inputClass = classNames(
        inputDefaultClass,
        !textArea && inputSizeClass,
        !isInvalid && inputFocusClass,
        !prefix && !suffix ? className : '',
        disabled && 'input-disabled',
        isInvalid && 'input-invalid',
        textArea && 'input-textarea',
    );

    const prefixNode = useRef();
    const suffixNode = useRef();

    const getAffixSize = () => {
        if (!prefixNode.current && !suffixNode.current) {
            return;
        }
        const prefixNodeWidth = prefixNode.current
            ? (prefixNode.current as HTMLElement).offsetWidth
            : '';
        const suffixNodeWidth = suffixNode.current
            ? (suffixNode.current as HTMLElement).offsetWidth
            : '';

        if (isNil(prefixNodeWidth) && isNil(suffixNodeWidth)) {
            return;
        }

        if (prefixNodeWidth) {
            setPrefixGutter(prefixNodeWidth);
        }

        if (suffixNodeWidth) {
            setSuffixGutter(suffixNodeWidth);
        }
    };

    useEffect(() => {
        getAffixSize();
    }, [prefix, suffix]);

    const remToPxConvertion = (pixel: number) => 0.0625 * pixel;

    const affixGutterStyle = () => {
        const leftGutter = `${remToPxConvertion(prefixGutter) + 1}rem`;
        const rightGutter = `${remToPxConvertion(suffixGutter) + 1}rem`;
        const gutterStyle: React.CSSProperties = {};

        if (direction === 'ltr') {
            if (prefix) {
                gutterStyle.paddingLeft = leftGutter;
            }

            if (suffix) {
                gutterStyle.paddingRight = rightGutter;
            }
        }

        if (direction === 'rtl') {
            if (prefix) {
                gutterStyle.paddingRight = leftGutter;
            }

            if (suffix) {
                gutterStyle.paddingLeft = rightGutter;
            }
        }

        return gutterStyle;
    };

    const inputProps = {
        className: !unstyle ? inputClass : '',
        disabled,
        type: type || 'text',
        ...field,
        ...rest,
    };

    const currencyInputProps = {
        className: !unstyle ? inputClass : '',
        disabled,
        type: type || 'text',
        onChangeValue,
        ...rest,
    };

    const renderTextArea = (
        <textarea style={{ ...style } as CSSProperties} {...inputProps} />
    );

    const renderCurrencyInput = <CurrencyInput {...currencyInputProps} />;

    const renderInput = (
        <input style={{ ...affixGutterStyle(), ...style }} {...inputProps} />
    );

    const renderAffixInput = (
        <span className={inputWrapperClass}>
            {prefix ? (
                <div
                    className="input-suffix-start"
                    ref={(node) => ((prefixNode.current as unknown as HTMLDivElement | null) = node)
          }
                >
                    {' '}
                    {prefix}{' '}
                </div>
            ) : null}
            {renderInput}
            {suffix ? (
                <div
                    className="input-suffix-end"
                    ref={(node) => ((suffixNode.current as unknown as HTMLDivElement | null) = node)
          }
                >
                    {suffix}
                </div>
            ) : null}
        </span>
    );

    const renderChildren = () => {
        if (currencyInput) {
            return renderCurrencyInput;
        }

        if (textArea) {
            return renderTextArea;
        }

        if (prefix || suffix) {
            return renderAffixInput;
        }
        return renderInput;
    };

    return renderChildren();
};

export default Input;
