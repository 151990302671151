import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { useConfig } from '../ConfigProvider';

interface OwnProps {
  children?: React.ReactNode;
  className?: string;
  clickable?: boolean;
  headerBorder?: boolean;
  bordered?: boolean;
  onClick?: () => void;
  bodyClass?: string;
  header?: string;
  headerClass?: string;
  headerExtra?: string;
  footer?: string;
  footerClass?: string;
  footerBorder?: boolean;
}

const Card: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { cardBordered } = useConfig();

    const {
        children,
        className,
        clickable,
        onClick,
        bordered = cardBordered || false,
        bodyClass,
        header,
        headerClass,
        headerBorder,
        headerExtra,
        footer,
        footerClass,
        footerBorder,
        ...rest
    } = props;

    const cardClass = classNames(
        'card',
        className,
        bordered ? 'card-border' : 'card-shadow',
        clickable && 'cursor-pointer user-select-none',
    );

    const cardBodyClasss = classNames('card-body', bodyClass);
    const cardHeaderClass = classNames(
        'card-header',
        headerBorder && 'card-header-border',
        headerExtra && 'card-header-extra',
        headerClass,
    );
    const cardFooterClass = classNames(
        'card-footer',
        footerBorder && 'card-footer-border',
        footerClass,
    );

    const renderHeader = () => {
        if (typeof header === 'string') {
            return <h4>{header}</h4>;
        }
        return <>{header}</>;
    };

    const onCardClick = () => {
        onClick?.();
    };

    return (
        <div className={cardClass} {...rest} onClick={onCardClick}>
            {header && (
                <div className={cardHeaderClass}>
                    {renderHeader()}
                    {headerExtra && <span>{headerExtra}</span>}
                </div>
            )}
            <div className={cardBodyClasss}>{children}</div>
            {footer && <div className={cardFooterClass}>{footer}</div>}
        </div>
    );
};

export default Card;
