import { combineReducers } from '@reduxjs/toolkit';
import common from './commonSlice';
import cliente from './clienteSlice';
import configAgenda from './configAgendaSlice';
import corporaConfig from './corporaConfigSlice';
import financeiro from './financeiroSlice';

const reducer = combineReducers({
    common,
    cliente,
    configAgenda,
    corporaConfig,
    financeiro,
});

export default reducer;
