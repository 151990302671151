import React, { useMemo } from 'react';
import classNames from 'classnames';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptbr from '@fullcalendar/core/locales/pt-br';

import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import { DateTime } from 'luxon';

export const eventColors = {
    red: {
        bg: 'bg-red-50 dark:bg-red-500/10',
        text: 'text-red-500 dark:text-red-100',
        dot: 'bg-red-500',
    },
    orange: {
        bg: 'bg-orange-50 dark:bg-orange-500/10',
        text: 'text-orange-500 dark:text-orange-100',
        dot: 'bg-orange-500',
    },
    amber: {
        bg: 'bg-amber-50 dark:bg-amber-500/10',
        text: 'text-amber-500 dark:text-amber-100',
        dot: 'bg-amber-500',
    },
    yellow: {
        bg: 'bg-yellow-50 dark:bg-yellow-500/10',
        text: 'text-yellow-500 dark:text-yellow-100',
        dot: 'bg-yellow-500',
    },
    lime: {
        bg: 'bg-lime-50 dark:bg-lime-500/10',
        text: 'text-lime-500 dark:text-lime-100',
        dot: 'bg-lime-500',
    },
    green: {
        bg: 'bg-green-50 dark:bg-green-500/10',
        text: 'text-green-500 dark:text-green-100',
        dot: 'bg-green-500',
    },
    emerald: {
        bg: 'bg-emerald-50 dark:bg-emerald-500/10',
        text: 'text-emerald-500 dark:text-emerald-100',
        dot: 'bg-emerald-500',
    },
    teal: {
        bg: 'bg-teal-50 dark:bg-teal-500/10',
        text: 'text-teal-500 dark:text-teal-100',
        dot: 'bg-teal-500',
    },
    cyan: {
        bg: 'bg-cyan-50 dark:bg-cyan-500/10',
        text: 'text-cyan-500 dark:text-cyan-100',
        dot: 'bg-cyan-500',
    },
    sky: {
        bg: 'bg-sky-50 dark:bg-sky-500/10',
        text: 'text-sky-900 dark:text-sky-500',
        dot: 'bg-sky-900',
    },
    blue: {
        bg: 'bg-blue-50 dark:bg-blue-500/10',
        text: 'text-blue-500 dark:text-blue-100',
        dot: 'bg-blue-500',
    },
    indigo: {
        bg: 'bg-indigo-800 ',
        text: 'text-white dark:text-white',
        dot: 'bg-indigo-500',
    },
    purple: {
        bg: 'bg-purple-800 dark:bg-purple-500/10',
        text: 'text-white dark:text-purple-100',
        dot: 'bg-purple-500',
    },
    fuchsia: {
        bg: 'bg-fuchsia-50 dark:bg-fuchsia-500/10',
        text: 'text-fuchsia-500 dark:text-fuchsia-100',
        dot: 'bg-fuchsia-500',
    },
    pink: {
        bg: 'bg-pink-50 dark:bg-pink-500/10',
        text: 'text-pink-500 dark:text-pink-100',
        dot: 'bg-pink-500',
    },
    rose: {
        bg: 'bg-rose-50 dark:bg-rose-500/10',
        text: 'text-rose-500 dark:text-rose-100',
        dot: 'bg-rose-500',
    },
    cancelado: {
        bg: 'flex justify-center items-center rounded-full bg-red-200 px-2 text-[10px] h-[25px]',
        text: 'text-red-400',
        dot: 'bg-red-400',
    },
    realizado: {
        bg: 'flex justify-center items-center rounded-full bg-lime-200 p-2 text-[10px] h-[25px]',
        text: 'text-lime-600',
        dot: 'bg-lime-600',
    },
    nao_compareceu: {
        bg: 'flex justify-center items-center rounded-full bg-gray-200 p-2 text-[10px] h-[25px]',
        text: 'text-gray-500',
        dot: 'bg-gray-500',
    },
    pendente: {
        bg: 'flex justify-center items-center rounded-full bg-red-400 p-2 text-[10px] h-[25px]',
        text: 'text-white',
        dot: 'bg-white',
    },
    previsto: {
        bg: 'flex justify-center items-center rounded-full border border-neutral-400 p-2 text-[10px] h-[25px]',
        text: 'text-black',
        dot: 'bg-white',
    },
    confirmado: {
        bg: 'flex justify-center items-center rounded-full bg-indigo-400 p-2 text-[10px] h-[25px]',
        text: 'text-white',
        dot: 'bg-white',
    },
    bloqueio: {
        bg: 'flex justify-center items-center rounded-full bg-orange-200 px-2 text-[10px] h-[25px]',
        text: 'text-orange-600',
        dot: 'bg-orange-600',
    },
    reservado: {
        bg: 'flex justify-center items-center rounded-full bg-green-200 px-2 text-[10px] h-[25px]',
        text: 'text-gray-500',
    },
    reserva_cancelada: {
        bg: 'flex justify-center items-center rounded-full bg-orange-200 px-2 text-[10px] h-[25px]',
        text: 'text-gray-500',
    },
}; 

const CalendarView = (props) => {
    const isResponsive = window.innerWidth <= 768;
    const calendarEvents = useMemo(() => {
        return Object.values(props.events)
            .flatMap(Object.values)
            .map((evt) => {
                const eventoData = DateTime.fromFormat(
                    evt.dataInicio,
                    'dd/MM/yyyy HH:mm',
                );
                const diaZeroHoras = DateTime.now().startOf('day');

                let eventColor = 'indigo';
                if (evt.categoria === 'BLOQUEIO') {
                    eventColor = 'bloqueio';
                } else if (
                    evt.status === 'CANCELADO'
          || evt.status === 'CANCELADO_PROFISSIONAL'
                ) {
                    eventColor = 'cancelado';
                } else if (evt.status === 'REALIZADO') {
                    eventColor = 'realizado';
                } else if (evt.status === 'NAO_COMPARECEU') {
                    eventColor = 'nao_compareceu';
                } else if (evt.status === 'PREVISTO') {
                    eventColor = 'previsto';
                } else if (evt.status === 'RESERVADO') {
                    eventColor = 'reservado';
                } else if (evt.status === 'CONFIRMADO') {
                    eventColor = 'confirmado';
                }
                  else if (evt.status === 'CANCELADO_RESERVA') {
                    eventColor = 'reserva_cancelada';
                }

                return {
                    id: evt.id,
                    uuid: evt.uuid,
                    servicoId: evt.servicoId,
                    localidadeServicoId: evt.localidadeServicoId,
                    title: evt.descricao,
                    categoria: evt.categoria,
                    start: DateTime.fromFormat(
                        evt.dataInicio,
                        'dd/MM/yyyy HH:mm',
                    ).toISO(),
                    end: DateTime.fromFormat(evt.dataFim, 'dd/MM/yyyy HH:mm').toISO(),
                    extendedProps: {
                        eventColor,
                        servicoId: evt.servicoId,
                        localidadeServicoId: evt.localidadeServicoId,
                        uuid: evt.uuid,
                        categoria: evt.categoria,
                        event: evt,
                    },
                };
            });
    }, [props.events]);

    const handleDatesSet = (arg) => {
        const start = DateTime.fromISO(arg.startStr).startOf('month');
        const end = DateTime.fromISO(arg.endStr).endOf('month');

        const startDate = start.toFormat('yyyy-MM-dd');
        const endDate = end.toFormat('yyyy-MM-dd');

        props.buscarAgendaProfissional(true, startDate, endDate);
    };

    return (
        <div
            className={classNames('calendar')}
            style={{ marginTop: isResponsive ? '140px' : '40px' }}
        >
            <FullCalendar
                locale={ptbr}
                initialView="dayGridMonth"
                longPressDelay={500}
                headerToolbar={{
                    left: 'title',
                    center: 'today',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay prev,next',
                }}
                height="1200px"
                events={calendarEvents}
                dateClick={props.dateClick}
                eventContent={(arg) => {
                    const { extendedProps } = arg.event;
                    const { isEnd, isStart } = arg;
                    const truncatedTitle = arg.event.title;

                    return (
                        <div
                            onClick={() => props.eventClick(extendedProps.event)}
                            className={classNames(
                                'custom-calendar-event',
                                'cursor-pointer',
                                extendedProps.eventColor
                                    ? eventColors[extendedProps.eventColor]?.bg
                                    : '',
                                extendedProps.eventColor
                                    ? eventColors[extendedProps.eventColor]?.text
                                    : '',
                                isEnd
                  && !isStart
                  && '!rounded-tl-none !rounded-bl-none !rtl:rounded-tr-none !rtl:rounded-br-none',
                                !isEnd
                  && isStart
                  && '!rounded-tr-none !rounded-br-none !rtl:rounded-tl-none !rtl:rounded-bl-none',
                            )}
                        >
                            {!(isEnd && !isStart) && <span>{arg.timeText}</span>}
                            <span
                                className={classNames(
                                    'font-semibold ml-1 rtl:mr-1',
                                    extendedProps.eventColor.text,
                                )}
                            >
                                {truncatedTitle}
                            </span>
                        </div>
                    );
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                datesSet={handleDatesSet}
            />
        </div>
    );
};

export default CalendarView;
