import React, { ForwardedRef, FunctionComponent, LegacyRef } from 'react';
import classNames from 'classnames';
import {
    HiChevronDown,
    HiChevronUp,
    HiChevronRight,
    HiChevronLeft,
} from 'react-icons/hi';
import { Placement } from '../../../types/ui';

interface OwnProps {
  placement: Placement;
  disabled?: boolean;
  className?: string;
  renderTitle?: React.ReactNode;
  children?: React.ReactNode;
  toggleClassName?: string;
  ref: ForwardedRef<unknown>;
  id?: string | undefined;
}

const DropdownToggleDefaultContent = ({
    placement,
    children,
}: {
  placement: Placement;
  children?: React.ReactNode;
}) => {
    // TODO: impl rtl handling
    if (placement && placement.includes('middle-start')) {
        return (
            <>
                {children}
                <HiChevronRight />
            </>
        );
    }

    if (placement && placement.includes('middle-end')) {
        return (
            <>
                <HiChevronLeft />
                {children}
            </>
        );
    }

    if (placement && placement.includes('top')) {
        return (
            <>
                {children}
                <HiChevronUp />
            </>
        );
    }

    return (
        <>
            {children}
            <HiChevronDown />
        </>
    );
};

const DropdownToggle = React.forwardRef(
    (props: OwnProps, refDropdownToggle) => {
        const {
            className,
            renderTitle,
            children,
            placement,
            disabled,
            toggleClassName,
            ref = refDropdownToggle,
            ...rest
        } = props;

        const toggleClass = 'dropdown-toggle';
        const disabledClass = 'dropdown-toggle-disabled';

        const dropdownToggleClass = classNames(
            toggleClass,
            className,
            toggleClassName,
            disabled && disabledClass,
        );

        const dropdownToggleDefaultClass = classNames(
            dropdownToggleClass,
            'dropdown-toggle-default',
        );

        if (renderTitle) {
            return (
                <div
                    className={dropdownToggleClass}
                    {...rest}
                    ref={ref as LegacyRef<HTMLDivElement> | undefined}
                >
                    {renderTitle}
                </div>
            );
        }

        return (
            <div
                ref={ref as LegacyRef<HTMLDivElement> | undefined}
                className={dropdownToggleDefaultClass}
                {...rest}
            >
                <span className="flex items-center">
                    <DropdownToggleDefaultContent placement={placement}>
                        {children}
                    </DropdownToggleDefaultContent>
                </span>
            </div>
        );
    },
);

export default DropdownToggle;
