import React, {
    ForwardedRef,
    forwardRef,
    FunctionComponent,
    LegacyRef,
} from 'react';
import classNames from 'classnames';
import { useTabs } from './context';

interface OwnProps {
  className?: string;
  children?: React.ReactNode;
  ref?: ForwardedRef<unknown>;
}

const TabList = React.forwardRef((props: OwnProps, refTabList) => {
    const {
        className, children, ref = refTabList, ...rest
    } = props;

    const { variant } = useTabs();

    const tabListClass = classNames('tab-list', `tab-list-${variant}`, className);

    return (
        <div
            role="tablist"
            className={tabListClass}
            ref={ref as LegacyRef<HTMLDivElement> | undefined}
            {...rest}
        >
            {children}
        </div>
    );
});

export default TabList;
