import ApiService from './ApiService';

export async function _apiSignIn(data) {
    return ApiService.fetchData({
        url: '/rest/init/login',
        method: 'post',
        data: { username: data.userName, password: data.password },
        headers: { 'Content-Type': 'application/json' },
    });
}

export async function _apiSignUp(data) {
    return ApiService.fetchData({
        url: '/rest/init/signup/terapeuta',
        method: 'post',
        data,
    });
}

export async function _esqueceuSenha(email) {
    return ApiService.fetchData({
        url: `/rest/init/esqueceuSenha/${email}`,
        method: 'get',
    });
}

export async function _trocarSenha(data) {
    return ApiService.fetchData({
        url: '/rest/init/trocarSenha',
        method: 'post',
        data,
    });
}

export async function _validarCadastro(uuid) {
    return ApiService.fetchData({
        url: `/rest/init/validacao/${uuid}`,
        method: 'get',
    });
}

export async function _agendar(data) {
    return ApiService.fetchData({
        url: '/rest/init/agenda',
        method: 'post',
        data,
    });
}

export async function _buscarQuestionarioCliente(formularioId) {
    return ApiService.fetchData({
        url: `/rest/init/formulario/${formularioId}`,
        method: 'get',
    });
}

export async function _salvarQuestionario(data) {
    return ApiService.fetchData({
        url: '/rest/init/formulario',
        method: 'post',
        data,
    });
}

export async function _salvarQuestionarioTecnica(data) {
    return ApiService.fetchData({
        url: '/rest/init/tecnica',
        method: 'post',
        data,
    });
}
