import React, { FunctionComponent, LegacyRef } from 'react';
import classNames from 'classnames';
import { useConfig } from '../ConfigProvider';
import { useForm } from '../Form/context';
import { useInputGroup } from '../InputGroup/context';
import useColorLevel from '../hooks/useColorLevel';
import { CONTROL_SIZES, SIZES } from '../utils/constant';
import Spinner from '../Spinner/Spinner';

interface OwnProps {
  children?: React.ReactNode;
  size?: string;
  color?: string;
  shape?: string;
  variant?: string;
  block?: boolean;
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  active?: boolean;
  danger?: string;
  textColor?: string;
  title?: string;
  onClick?: (e?: React.FormEvent) => void;
  onSubmit?: (e?: React.FormEvent) => void;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

const Button: FunctionComponent<OwnProps> = React.forwardRef(
    (props: OwnProps, ref) => {
        const {
            children,
            size,
            color,
            shape,
            variant,
            block,
            icon,
            className,
            disabled,
            loading,
            active,
            danger,
            type,
            onClick,
            onSubmit,
            textColor,
            ...rest
        } = props;
        const { themeColor, controlSize, primaryColorLevel } = useConfig();
        const formControlSize = useForm()?.size;
        const inputGroupSize = useInputGroup()?.size;
        const defaultClass = 'button';
        const sizeIconClass = 'inline-flex items-center justify-center';

        const auxColor = color || '';
        const splitedColor = auxColor?.split('-');

        const buttonSize = size || inputGroupSize || formControlSize || controlSize;
        const buttonColor = splitedColor && (splitedColor[0] || themeColor);
        const buttonColorLevel = splitedColor && (splitedColor[1] || primaryColorLevel);

        const [increaseLevel, decreaseLevel] = useColorLevel(buttonColorLevel);

        const auxActive = active !== undefined ? active : false;
        const auxLoading = loading !== undefined ? loading : false;
        const auxDisabled = disabled !== undefined ? disabled : false;

        const getButtonSize = () => {
            let sizeClass: string;
            switch (buttonSize) {
                case SIZES.LG:
                    sizeClass = classNames(
                        `h-${CONTROL_SIZES.lg}`,
                        icon && !children
                            ? `w-${CONTROL_SIZES.lg} ${sizeIconClass} text-2xl`
                            : 'px-8 py-2 text-base',
                    );
                    break;
                case SIZES.SM:
                    sizeClass = classNames(
                        `h-${CONTROL_SIZES.sm}`,
                        icon && !children
                            ? `w-${CONTROL_SIZES.sm} ${sizeIconClass} text-lg`
                            : 'px-3 py-2 text-sm',
                    );
                    break;
                case SIZES.XS:
                    sizeClass = classNames(
                        `h-${CONTROL_SIZES.xs}`,
                        icon && !children
                            ? `w-${CONTROL_SIZES.xs} ${sizeIconClass} text-base`
                            : 'px-3 py-1 text-xs',
                    );
                    break;
                default:
                    sizeClass = classNames(
                        `h-${CONTROL_SIZES.md}`,
                        icon && !children
                            ? `w-${CONTROL_SIZES.md} ${sizeIconClass} text-xl`
                            : 'px-8 py-2',
                    );
                    break;
            }
            return sizeClass;
        };

        const disabledClass = 'opacity-50 cursor-not-allowed';

        const solidColor = () => {
            let backgroundButton: string;
            if (auxActive) {
                backgroundButton = `bg-${buttonColor}-${increaseLevel}`;
            } else {
                if (buttonColor && buttonColorLevel) {
                    backgroundButton = `bg-${buttonColor}-${buttonColorLevel}`;
                } else {
                    backgroundButton = `bg-${themeColor}-${primaryColorLevel}`;
                }
            }

            const btn = {
                bgColor: backgroundButton,
                textColor: textColor || 'text-white',
                hoverColor: auxActive ? '' : `hover:bg-${buttonColor}-${decreaseLevel}`,
                activeColor: `active:bg-${buttonColor}-${increaseLevel}`,
            };
            return getBtnColor(btn);
        };

        const twoToneColor = () => {
            const btn = {
                bgColor: auxActive
                    ? `bg-${buttonColor}-200 dark:bg-${buttonColor}-50`
                    : `bg-${buttonColor}-50 dark:bg-${buttonColor}-500 dark:bg-opacity-20`,
                textColor: `text-${buttonColor}-${buttonColorLevel} dark:text-${buttonColor}-50`,
                hoverColor: auxActive
                    ? ''
                    : `hover:bg-${buttonColor}-100 dark:hover:bg-${buttonColor}-500 dark:hover:bg-opacity-30`,
                activeColor: `active:bg-${buttonColor}-200 dark:active:bg-${buttonColor}-500 dark:active:bg-opacity-40`,
            };
            return getBtnColor(btn);
        };

        const defaultColor = () => {
            const btn = {
                bgColor: auxActive
                    ? 'bg-gray-100 border border-gray-300 dark:bg-gray-500 dark:border-gray-500'
                    : 'bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700',
                textColor: 'text-gray-600 dark:text-gray-100',
                hoverColor: auxActive ? '' : 'hover:bg-gray-50 dark:hover:bg-gray-600',
                activeColor:
          'active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500',
            };
            return getBtnColor(btn);
        };

        const plainColor = () => {
            const btn = {
                bgColor: auxActive
                    ? 'bg-gray-100 dark:bg-gray-500'
                    : 'bg-transparent border border-transparent',
                textColor: 'text-gray-600 dark:text-gray-100',
                hoverColor: auxActive ? '' : 'hover:bg-gray-50 dark:hover:bg-gray-600',
                activeColor:
          'active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500',
            };
            return getBtnColor(btn);
        };

        const getBtnColor = ({
            bgColor,
            hoverColor,
            activeColor,
            textColor,
        }: {
      bgColor: string;
      hoverColor: string;
      activeColor: string;
      textColor: string;
    }) => {
            return `${bgColor} ${
                auxDisabled || auxLoading
                    ? disabledClass
                    : `${hoverColor} ${activeColor}`
            } ${textColor}`;
        };

        const btnColor = () => {
            const auxVariant = variant || 'default';

            switch (auxVariant) {
                case 'solid':
                    return solidColor();
                case 'twoTone':
                    return twoToneColor();
                case 'plain':
                    return plainColor();
                case 'default':
                    return defaultColor();
                default:
                    return defaultColor();
            }
        };

        const classes = classNames(
            defaultClass,
            btnColor(),
            `radius-${shape || 'round'}`,
            getButtonSize(),
            className,
            block ? 'w-full' : '',
        );

        const handleClick = (e: React.FormEvent) => {
            if (auxDisabled || auxLoading) {
                e.preventDefault();
                return;
            }

            onClick?.(e);
        };

        const handleSubmit = (e: React.FormEvent) => {
            if (auxDisabled || auxLoading) {
                e.preventDefault();
                return;
            }

            onSubmit?.(e);
        };

        const renderChildren = () => {
            if (auxLoading && children) {
                return (
                    <span className="flex items-center justify-center">
                        <Spinner enableTheme={false} className="mr-1" isSpining />
                        {children}
                    </span>
                );
            }

            if (icon && !children && auxLoading) {
                return <Spinner enableTheme={false} isSpining />;
            }

            if (icon && !children && !auxLoading) {
                return <>{icon}</>;
            }

            if (icon && children && !auxLoading) {
                return (
                    <span className="flex items-center justify-center">
                        <span className="text-lg">{icon}</span>
                        <span className="ltr:ml-1 rtl:mr-1">{children}</span>
                    </span>
                );
            }

            return <>{children}</>;
        };

        return (
            <button
                ref={ref as LegacyRef<HTMLButtonElement> | undefined}
                type={type || 'button'}
                className={classes}
                {...rest}
                onClick={handleClick}
                onSubmit={handleSubmit}
            >
                {renderChildren()}
            </button>
        );
    },
);

export default Button;
