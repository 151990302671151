import { combineReducers } from 'redux';
import theme from './theme/themeSlice';
import auth from './auth';
import base from './base';
import locale from './locale/localeSlice';

const rootReducer = combineReducers({
    theme,
    auth,
    base,
    locale,
});

export default rootReducer;
