import React, {
    FormEvent,
    FunctionComponent,
    LegacyRef,
    ReactNode,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

interface OwnProps {
  children?: ReactNode;
  isActive?: boolean;
  disabled?: boolean;
  className?: string;
  style?: StyleSheet;
  eventKey?: string;
  variant?: string;
  onSelect?: (eventKey: string | undefined, e: FormEvent) => void;
  ref?: LegacyRef<HTMLDivElement> | undefined;
  menuItemHeight?: number;
  id?: string;
}

const MenuItem = React.forwardRef((props: OwnProps, refMenuItem) => {
    const {
        children,
        className,
        disabled,
        eventKey,
        isActive,
        menuItemHeight,
        onSelect,
        style,
        variant,
        ref = refMenuItem,
        ...rest
    } = props;

    const menuItemActiveClass = 'menu-item-active';
    const menuItemHoverClass = 'menu-item-hoverable';
    const disabledClass = 'menu-item-disabled';
    const menuItemClass = classNames(
        'menu-item',
        `menu-item-${variant}`,
        isActive && menuItemActiveClass,
        disabled && disabledClass,
        !disabled && menuItemHoverClass,
        className,
    );

    const hanldeOnClick = (e: FormEvent) => {
        if (onSelect) {
            onSelect(eventKey, e);
        }
    };

    return (
        <div
            ref={ref as LegacyRef<HTMLDivElement> | undefined}
            className={menuItemClass}
            style={{ height: `${menuItemHeight}px`, ...style }}
            onClick={hanldeOnClick}
            {...rest}
        >
            {children}
        </div>
    );
});

export default MenuItem;
