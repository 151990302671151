import { useRecoilState } from 'recoil';
import { ModalProps } from '../../components/shared/Modal/ModalMessage';
import { modalState } from '../../recoil/modal';

export default function useModal() {
    const [modal, setModal] = useRecoilState(modalState);

    const showModal = (modalProps: ModalProps) => {
        setModal(modalProps);
    };

    const hideModal = () => {
        setModal(null);
    };

    return {
        modal,
        setModal,
        showModal,
        hideModal,
    };
}
